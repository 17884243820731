import { AppUserSettingService } from '@aca-new/app/shared/services/user-services/app-user-setting/app-user-setting.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AnalyticsOnlyGuard implements CanActivate {
  public constructor(
    private readonly _appUserSettingService: AppUserSettingService,
    private readonly _router: Router
  ) {}

  public canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    if (this._appUserSettingService.getIsQimaProduce()) {
      if (state.url.startsWith('/payments-for-aca')) {
        return true;
      }

      this._gotoAnalytics();

      return false;
    }

    if (this._appUserSettingService.getIsSuperMaster() && this._appUserSettingService.getAccessDashboardOnly()) {
      this._gotoAnalytics();

      return false;
    }

    return true;
  }

  private _gotoAnalytics(): void {
    void this._router.navigate(['/analytics']);
  }
}
