import { ChecklistsModule } from '@aca-new/app/pages/checklists/checklists.module';
import { DocumentVerificationModule } from '@aca-new/app/pages/document-verification/document-verification.module';
import { ImageViewerModule } from '@aca-new/app/pages/image-viewer/image-viewer.module';
import { MainComponent } from '@aca-new/app/pages/main/main.component';
import { AcaFrameWrapperModule } from '@aca-new/app/pages/main/shared/components/aca-frame-wrapper/aca-frame-wrapper.module';
import { PageNotFoundComponent } from '@aca-new/app/shared/components/page-not-found/page-not-found.component';
import { AnalyticsOnlyGuard } from '@aca-new/app/shared/guards/analytics-only-guard.service';
import { AuthoritiesGuard } from '@aca-new/app/shared/guards/authorities/authorities.guard';
import { DomainGuard } from '@aca-new/app/shared/guards/domain-guard.service';
import { EntryGuard } from '@aca-new/app/shared/guards/entry-guard.service';
import { TitleFaviconResolver } from '@aca-new/app/shared/resolvers/title-favicon-resolver.ts/title-favicon-resolver.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const ROUTES: Routes = [
  // Site layout routes goes here
  {
    path: '',
    canActivate: [DomainGuard, EntryGuard],
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        title: TitleFaviconResolver,
        data: {
          altTitle: 'Homepage',
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        loadChildren: (): Promise<unknown> => import('./pages/home/home.module').then((m): unknown => m.HomeModule),
      },
      {
        path: 'analytics',
        title: TitleFaviconResolver,
        data: {
          altTitle: 'Analytics',
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        loadChildren: (): Promise<unknown> => import('./pages/analytics/analytics.module').then((m): unknown => m.AnalyticsModule),
      },
      {
        path: 'bookings',
        title: TitleFaviconResolver,
        data: {
          altTitle: 'Bookings',
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        loadChildren: (): Promise<unknown> => import('./pages/bookings/bookings.module').then((m): unknown => m.BookingsModule),
      },
      {
        path: 'book-inspection',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        loadChildren: (): Promise<unknown> => import('./pages/book/pages/product-inspections/product-inspections.module').then((m): unknown => m.ProductInspectionsModule),
      },
      {
        path: 'book-checkout/:draftId',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        loadChildren: (): Promise<unknown> => import('./pages/book/pages/checkout/checkout.module').then((m): unknown => m.CheckoutModule),
      },
      {
        path: 'book-confirmation/:serviceType/:orderId',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        loadChildren: (): Promise<unknown> => import('./pages/book/pages/confirmation/confirmation.module').then((m): unknown => m.ConfirmationModule),
      },
      {
        path: 'reports',
        title: TitleFaviconResolver,
        data: {
          altTitle: 'Reports',
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        loadChildren: (): Promise<unknown> => import('./pages/reports/reports.module').then((m): unknown => m.ReportsModule),
      },
      {
        path: 'produce-reports',
        title: TitleFaviconResolver,
        data: {
          altTitle: 'Produce Reports',
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        loadChildren: (): Promise<unknown> => import('./pages/produce-reports/produce-reports.module').then((m): unknown => m.ProduceReportsModule),
      },
      {
        path: 'supplier-documents',
        title: TitleFaviconResolver,
        data: {
          altTitle: 'Supplier Documents',
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        loadChildren: (): Promise<unknown> => import('./pages/supplier-documents/supplier-documents.module').then((m): unknown => m.SupplierDocumentsModule),
      },
      {
        path: 'checklists',
        title: TitleFaviconResolver,
        data: {
          altTitle: 'Checklists',
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        loadChildren: (): Promise<ChecklistsModule> => import('./pages/checklists/checklists.module').then((m): ChecklistsModule => m.ChecklistsModule),
      },
      {
        path: '',
        title: TitleFaviconResolver,
        data: {
          altTitle: 'Suppliers & Sites',
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        loadChildren: (): Promise<unknown> => import('./pages/suppliers/suppliers.module').then((m): unknown => m.SuppliersModule),
      },
      {
        path: 'payments',
        title: TitleFaviconResolver,
        data: {
          altTitle: 'Payments',
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        loadChildren: (): Promise<unknown> => import('./pages/payments/payments.module').then((m): unknown => m.PaymentsModule),
      },
      {
        path: 'account',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        loadChildren: (): Promise<unknown> => import('./pages/account/account.module').then((m): unknown => m.AccountModule),
      },
      {
        path: 'network',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        loadChildren: (): Promise<unknown> => import('./pages/network/network.module').then((m): unknown => m.NetworkModule),
      },
      {
        path: 'aca',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        loadChildren: (): Promise<AcaFrameWrapperModule> =>
          import('./pages/main/shared/components/aca-frame-wrapper/aca-frame-wrapper.module').then((m): AcaFrameWrapperModule => m.AcaFrameWrapperModule),
      },
      {
        path: 'interactive-report/:orderId/:productId',
        title: TitleFaviconResolver,
        data: {
          altTitle: 'Reports | Detailed',
        },
        canActivate: [DomainGuard],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        loadChildren: (): Promise<unknown> =>
          import('@aca-new/app/pages/reports/pages/interactive-report/interactive-report.module').then((m): unknown => m.InteractiveReportModule),
      },
      {
        path: 'reflow-report/:orderId/:orderNumber',
        title: TitleFaviconResolver,
        data: {
          altTitle: 'Reflow',
        },
        canActivate: [DomainGuard],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        loadChildren: (): Promise<unknown> => import('./pages/reflow/reflow.module').then((m): unknown => m.ReflowModule),
      },
    ],
  },

  // No layout routes goes here
  {
    path: 'html-report/:orderId/:productId/:mode',
    canActivate: [DomainGuard],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    loadChildren: (): Promise<unknown> => import('@aca-new/app/pages/reports/pages/interactive-report/interactive-report.module').then((m): unknown => m.InteractiveReportModule),
  },
  {
    path: 'reflow/:orderId/:orderNumber',
    canActivate: [DomainGuard],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    loadChildren: (): Promise<unknown> => import('./pages/reflow/reflow.module').then((m): unknown => m.ReflowModule),
  },
  {
    path: 'image-viewer',
    title: TitleFaviconResolver,
    data: {
      altTitle: 'Image Viewer',
      altFavicon: 'favicon2.ico',
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    loadChildren: (): Promise<ImageViewerModule> => import('./pages/image-viewer/image-viewer.module').then((m): ImageViewerModule => m.ImageViewerModule),
  },
  {
    path: 'supplier-confirmation/:orderId',
    title: TitleFaviconResolver,
    data: {
      altTitle: 'Supplier Confirmation',
      altFavicon: 'favicon2.ico',
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    loadChildren: (): Promise<unknown> => import('./pages/supplier-confirmation/supplier-confirmation.module').then((m): unknown => m.SupplierConfirmationModule),
  },
  {
    path: 'pay',
    title: TitleFaviconResolver,
    data: {
      altTitle: 'Payment Portal',
      altFavicon: 'favicon2.ico',
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    loadChildren: (): Promise<unknown> => import('./pages/payment-link/payment-link.module').then((m): unknown => m.PaymentLinkModule),
  },
  {
    path: 'payments-for-aca',
    title: TitleFaviconResolver,
    data: {
      altTitle: 'Payments',
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    loadChildren: (): Promise<unknown> => import('./pages/payments/payments.module').then((m): unknown => m.PaymentsModule),
  },
  {
    path: 'payment-checkout-aca/:orderId',
    canActivate: [AuthoritiesGuard, AnalyticsOnlyGuard],
    title: TitleFaviconResolver,
    data: {
      altTitle: 'Payment Checkout',
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    loadComponent: (): Promise<unknown> => import('./pages/payment-checkout/payment-checkout.component').then((m): unknown => m.PaymentCheckoutComponent),
  },
  {
    path: 'shared-report/:token',
    title: TitleFaviconResolver,
    data: {
      altTitle: 'Interactive Report',
      altFavicon: 'favicon2.ico',
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    loadChildren: (): Promise<unknown> => import('./pages/reports/pages/external-shared-report/external-shared-report.module').then((m): unknown => m.ExternalSharedReportModule),
  },
  {
    path: 'login',
    title: TitleFaviconResolver,
    canActivate: [DomainGuard],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    loadChildren: (): Promise<unknown> => import('./pages/user/pages/login/login.module').then((m): unknown => m.LoginModule),
  },
  {
    path: 'document-verification',
    title: TitleFaviconResolver,
    canActivate: [DomainGuard],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    loadChildren: (): Promise<unknown> =>
      import('./pages/document-verification/document-verification.module').then((m): DocumentVerificationModule => m.DocumentVerificationModule),
  },
  {
    path: '404',
    title: TitleFaviconResolver,
    data: {
      altTitle: '404',
      altFavicon: 'favicon2.ico',
    },
    component: PageNotFoundComponent,
  },
  {
    path: 'entity/:entityId/accept-invite',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    loadChildren: (): Promise<unknown> =>
      import('./pages/network/pages/create-supplier-account/supplier-account-external.module').then((m): unknown => m.SupplierAccountExternalModule),
  },

  // otherwise, redirect to home page
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: [DomainGuard, EntryGuard],
})
export class AppRoutingModule {}
